html {
  position: relative;
  min-height: 100%;
}
body {
  margin-bottom: 60px; /* Margin bottom by footer height */
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px; /* Set the fixed height of the footer here */
  line-height: 60px; /* Vertically center the text there */
  background-color: #f5f5f5;
}
.navbar-brand img {
  height: 25px;
}

.features i {
  font-size: 48px;
  color: #003699;
}

a.btn-primary,
a.btn-primary:hover,
button.btn-primary,
button.btn-primary:hover {
  background-color: #003699;
  border-color: #003699;
}

a,
a:hover {
  color: #003699;
}

.card-text p {
  margin-bottom: 0;
}

.ql-editor {
  min-height: 250px;
}

.ql-toolbar.ql-snow {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.ql-container.ql-snow {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
